import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// material
import { styled } from '@mui/material/styles';
import {
    Box,
    Toolbar,
    Tooltip,
    IconButton,
    Typography,
    OutlinedInput,
    InputAdornment,
    Select
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';

    // ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 50,
    width:"100%",
    display: 'flex',
    '@media (min-width: 600px)': {
        paddingLeft: "5px",
        paddingRight:"5px"
    },
}));


const SearchStyle = styled(Select)(({ theme }) => ({
    width:"100%",
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: "100%", boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    }
}));

    // ----------------------------------------------------------------------

FilterVouchers.propTypes = {
    numSelected: PropTypes.number,
    filter: PropTypes.string,
    onFilter: PropTypes.func,
    filterPlaceHolder: PropTypes.string
};

export default function FilterVouchers({ numSelected, filter, onFilter, filterPlaceHolder }) {
    return (
        <RootStyle
            sx={{
                ...(numSelected > 0 && {
                color: 'primary.main',
                bgcolor: 'primary.lighter',
                })
            }}
        >
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <FormControl fullWidth>
                    <SearchStyle
                        displayEmpty
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return `${filterPlaceHolder}`;
                            }
                            if(selected == 0) {
                                return "Valid";
                            }
                            if(selected == 1) {
                                return "Expired";
                            }
                            if(selected == 2) {
                                return "Used";
                            }
                        }}
                        sx={{paddingLeft:"5px",
                            width:"100%",
                            '@media (min-width: 1300px)': {
                                fontSize:"12px",
                            },
                            color:"rgb(118, 118, 118)"
                        }}
                        size="small"
                        value={filter}
                        onChange={onFilter}
                        startAdornment={
                            <InputAdornment position="start">
                            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                        }
                    >
                        <MenuItem value=''>
                            {`${filterPlaceHolder}`}
                        </MenuItem>
                        <MenuItem value={String(0)}>Valid</MenuItem>
                        <MenuItem value={String(1)}>Expired</MenuItem>
                        <MenuItem value={String(2)}>Used</MenuItem>
                    </SearchStyle>
                </FormControl>
            )}
        </RootStyle>
    );
}