import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Label from '../components/Label';
import { sentenceCase } from 'change-case';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import './generalcert.css';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Masonry from '@mui/lab/Masonry';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Modal from '@mui/material/Modal';
import { useLocation } from 'react-router-dom';
import Page from '../components/Page';
import Download from './Download';
import _ from 'lodash';
import axios from 'axios';
import LoopIcon from '@mui/icons-material/Loop';
import {
  Card,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Table,
  Box,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Button,
  Skeleton
} from '@mui/material';
const isMobile = window.innerWidth <= 768;
const useStyles = makeStyles((theme) => ({
  contentCell: {
    textAlign: 'center'
  },
  title: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '27px',
    color: 'black',
    marginBottom: theme.spacing(0.5),
    padding: '0px 5px'
  },
  value: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '27px',
    color: 'black',
    marginBottom: theme.spacing(0)
  },
  located: {
    marginTop: '2px'
  },
  status: {},
  statusLabel: {
    fontSize: '1.1rem',
    fontWeight: 500,
    color: '#333'
  },
  button: {
    width: '100%',
    maxWidth: 300,
    margin: `${theme.spacing(2)}px auto 0`,
    padding: theme.spacing(1.2),
    background: '#0066cc',
    '&:hover': {
      background: '#0052a3'
    },
    borderRadius: 8,
    textTransform: 'none',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'center'
  }
}));

function GeneralCert({ view, certImages }) {
  const classes = useStyles();
  const [certData, setCertData] = useState({});
  const [qrSrc, setQrSrc] = useState('');
  const [src, setSrc] = useState('');
  const [verId, setVerId] = useState('');
  const [verDate, setVerDate] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [quarantinDays, setQurantinDays] = useState('');
  const [status, setStatus] = useState('');
  const [trueStatus, setTrueStatus] = useState('');
  const [verDesc, setVerDesc] = useState('');
  const [initiatorName, setInitiatorName] = useState('');
  const [url, setUrl] = useState('');
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState('');
  const [modal, setModal] = useState(false);
  const location = useLocation();
  const [size, setSize] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState([]);
  const [withTime, setWithTime] = useState(false);
  const [loading, setLoading] = useState(false);
  const isFirstRun = useRef(true);
  const isSecondRun = useRef(true);
  const [imageSrc, setImageSrc] = useState([])
  const imageRef = useRef({});

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const currentView = view || view;
    if (currentView) {
      setCertData(currentView);
      setQrSrc(currentView.qr);
      const date = currentView.date;
      const finalDate = moment(date).format('D MMM, YYYY h:mm A');
      setSrc(currentView.qr);
      setVerId(currentView.id);
      setVerDate(finalDate);
      setName(currentView.name || currentView.receiver_name);
      setEmail(currentView.email);
      setAddress(currentView.address);
      setQurantinDays(currentView.quarantine_days);
      setStatus(currentView.status);
      setTrueStatus(currentView.true_status);
      setVerDesc(currentView.desc);
      setInitiatorName(currentView.initiator_name);
    }
  }, [view]);

  const renderStatus = () => {
    const status_value = status;
    const true_status = trueStatus;

    if (status_value.toLowerCase() === 'true' && true_status.toLowerCase() === 'true') {
      return (
        <Box sx={{ textAlign: 'center', margin: '1px 8px 5px 6px' }}>
          <Label
            variant="ghost"
            sx={{
              backgroundColor: '#01915f',
              padding: '4px 10px',
              borderRadius: '30px'
            }}
          >
            <span style={{ fontSize: '15px', color: 'white', fontWeight: '400' }}>
              {sentenceCase('verified')}
            </span>
          </Label>
        </Box>
      );
    }

    if (status_value.toLowerCase() === 'true' && true_status.toLowerCase() === 'false') {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Label variant="ghost" color={'error'} sx={{ padding: '14px' }}>
            {sentenceCase('unverified')}
          </Label>
        </Box>
      );
    }

    if (status_value.toLowerCase() === 'false' && true_status.toLowerCase() === 'true') {
      return (
        <>
          <Label variant="ghost" color={'warning'} sx={{ padding: '14px' }}>
            {sentenceCase('pending')}
          </Label>
        </>
      );
    }

    if (status_value.toLowerCase() === 'false' && true_status.toLowerCase() === 'false') {
      return (
        <>
          <Label variant="ghost" color={'error'} sx={{ padding: '14px' }}>
            {sentenceCase('unverified')}
          </Label>
        </>
      );
    }
  };

  const renderSuccesOrFailed = () => {
    const status_value = status;
    const true_status = trueStatus;
    if (status_value.toLowerCase() == 'true' && true_status.toLowerCase() == 'true') {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Label
            variant="ghost"
            sx={{
              backgroundColor: 'white',
              padding: '14px 8px',
              border: '1px solid #01915f',
              borderRadius: '30px',
              fontSize: '13px'
            }}
          >
            <span style={{ color: '#01915f' }}>
              <TaskAltIcon />
            </span>
            <span style={{ fontSize: '13px', color: '#01915f' }}>{sentenceCase('Success')}</span>
          </Label>
        </Box>
      );
    }
    if (status_value.toLowerCase() == 'true' && true_status.toLowerCase() == 'false') {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Label variant="ghost" color={'error'} sx={{ padding: '14px' }}>
            {sentenceCase('unverified')}
          </Label>
        </Box>
      );
    }
    if (status_value.toLowerCase() == 'false' && true_status.toLowerCase() == 'true') {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Label variant="ghost" color={'warning'} sx={{ padding: '14px' }}>
            {sentenceCase('pending')}
          </Label>
        </Box>
      );
    }
    if (status_value.toLowerCase() == 'false' && true_status.toLowerCase() == 'false') {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Label variant="ghost" color={'error'} sx={{ padding: '14px' }}>
            {sentenceCase('unverified')}
          </Label>
        </Box>
      );
    }
  };

  const renderLoadingSkeleton = () => (
    <>
      <Skeleton variant="rectangular" height={40} sx={{ mb: 2 }} />
      <Skeleton variant="text" height={20} sx={{ mb: 1 }} />
      <Skeleton variant="text" height={20} sx={{ mb: 1 }} />
      <Skeleton variant="rectangular" height={120} sx={{ mt: 2 }} />
    </>
  );

  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (!_.isEmpty(certImages.data)) {
      let data = Object.values(certImages.data);
      var a = [];
      a = data.map((i, index) => {
        return {
          img: 'data:image/jpeg;base64,' + i.thumbnail_base64,
          title: i.caption,
          date: i.image_date,
          location: i.address,
          id: i.image_id,
          check_box_no: index,
          lat: i.lat,
          long: i.long
        };
      });
      setImages(a);
      const dataArray = a.map((i) => i.check_box_no);
      const initialState = dataArray.reduce((o, key) => ({ ...o, [key]: false }), {});
      setChecked(initialState);
    }
  }, [certImages]);

  const getSingleImage = async (id, i) => {
    try {
      if (images[i].isHighQuality) {
        console.log('High-quality image already loaded');
        return;
      }
      setLoading(true);
      const data = new FormData();
      data.append('image_id', id);
      const response = await axios.post('https://irhere.com.au/api/get_image_from_id', data);
      const highQualityImage = response.data[0]?.image_base64;
      setImages((prevImages) => {
        const newImages = [...prevImages];
        newImages[i].img = `data:image/gif;base64,${highQualityImage}`;
        newImages[i].isHighQuality = true;
        return newImages;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(certImages.data)) {
      const rawData = Object.values(certImages.data);

      // Step 1: Prepare initial imageSrc with thumbnails
      const formattedImages = rawData.map(item => ({
        ...item,
        img: `data:image/gif;base64,${item.thumbnail_base64}`,
        isHighQuality: false,
      }));

      setImageSrc(formattedImages);

      // Step 2: After setting initial images, load high-quality versions
      setTimeout(() => {
        formattedImages.forEach((img, index) => {
          getHighQualityImage(img.image_id, index);
        });
      }, 0); // setTimeout ensures imageSrc is set first
    }
  }, [certImages]);

  // Function to fetch and update with high-quality image
  const getHighQualityImage = async (id, i) => {
    try {
      setImageSrc(prev => {
        if (prev[i]?.isHighQuality) return prev;
        return prev;
      });

      setLoading(true);
      const formData = new FormData();
      formData.append('image_id', id);
      const response = await axios.post('https://irhere.com.au/api/get_image_from_id', formData);
      const highQualityImage = response.data[0]?.image_base64;

      setImageSrc(prevImages => {
        const updated = [...prevImages];
        updated[i] = {
          ...updated[i],
          img: `data:image/gif;base64,${highQualityImage}`,
          isHighQuality: true,
        };
        return updated;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const closeButton = () => {
    setUrl('');
    setModal(false);
  };

  const openModal = (index) => {
    setUrl(images[index]);
    setIndex(index);
    setModal(true);
  };

  var zip = JSZip();

  const dataURltoBlob = (dataURl) => {
    var byteString = atob(dataURl.split(',')[1]);

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var bb = new Blob([ab]);
    return bb;
  };

  const download = () => {
    zip.generateAsync({ type: 'blob' }).then(function (blob) {
      saveAs(blob, 'images.zip');
    });
  };

  const generateZip = (arr) => {
    if (arr.length > 0) {
      arr.map((a) => {
        var image = dataURltoBlob(a.img);
        zip.file(`images/image_${a.index}.png`, image, {
          binary: true
        });
      });
      download();
    }
  };

  const handleDownloads = () => {
    if (imageRef.current) {
      html2canvas(imageRef.current, { scale: 2 }).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "image_with_text.png";
        link.click();
      });
    }
  };
  const handleDownload = async () => {
    let arr = [];
    let img_index = Object.keys(checked).filter((item) => checked[item] === true);
    if (!imageSrc[i]?.isHighQuality) {
      await getHighQualityImage(imageSrc[i].image_id, i);
    }
    if (withTime) {
      const numberOfImages = img_index.length;
      img_index.map((i, index) => {
        const input = document.getElementById(`downloadablediv${i}`);
        html2canvas(input, {
          scale: window.devicePixelRatio,
          useCORS: true,
          allowTaint: true,
          logging: true,
          onclone: function (clonedDoc) {
            clonedDoc.getElementById(`downloadablediv${i}`).style.display = 'block';
          }
        }).then((canvas) => {
          const imgageData = canvas.toDataURL('image/png', 1.0);
          arr[i] = { index: i, img: imgageData };
          if (index + 1 === numberOfImages) {
            generateZip(arr);
          }
        });
      });
    } else {
      const numberOfImages = img_index.length;
      let completedCount = 0;

      img_index.forEach((i, index) => {
        const element = imageRef.current[i];
        if (element) {
          html2canvas(element, {
            scale: 2,
            useCORS: true,
            allowTaint: true
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            arr[i] = { index: i, img: imgData };
            completedCount++;

            if (completedCount === numberOfImages) {
              generateZip(arr);
            }
          });
        }
      });
    }
  };


  const onMoveNextRequest = (index) => {
    const modifiedIndex = (index + 1) % images.length;
    const imageId = images[modifiedIndex].id;
    setIndex(modifiedIndex);
    getSingleImage(imageId, modifiedIndex);
  };

  const onMovePrevRequest = (index) => {
    const modifiedIndex = (index + images.length - 1) % images.length;
    const imageId = images[modifiedIndex].id;
    setIndex(modifiedIndex);
    getSingleImage(imageId, modifiedIndex);
  };

  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  const timeToggleCheck = (input) => {
    setWithTime((prevState) => {
      const newState = { ...prevState };
      newState[input] = !prevState[input];
      return newState;
    });
  };

  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }

      if (!value) {
        imageRef.current = {}; // clear refs when unselecting
      }

      return newState;
    });
  };
  const renderImages = () => {
    if (!_.isEmpty(certImages.data)) {
      let data = Object.values(certImages.data);
      console.log('renderImages', 'data', data)

      return (
        <>
          <TableRow>
            <Box sx={{ padding: '1px 5px 1px 26px' }}>
              <Masonry
                columns={4}
                className="gridcontainer"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {data.map((i, index) => (
                  console.log('renderImages', i),
                  <Grid
                    item
                    key={index}
                    style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
                  >
                    <input
                      id={`checkboxid-${index}`}
                      name={index}
                      value={index}
                      type="checkbox"
                      className="checkbox"
                      onChange={() => toggleCheck(index)}
                      checked={checked[index]}
                    />
                    <Grid
                      onClick={() => {
                        openModal(index);
                        getSingleImage(i.image_id, index);
                        console.log('getSingleImage', getSingleImage(i.image_id, index))
                      }}
                      className="container"
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <div ref={(el) => {
                        // Ensure imageRef.current is always an object
                        if (imageRef.current === null) {
                          imageRef.current = {};
                        }

                        if (el && typeof index !== 'undefined' && checked?.[index]) {
                          imageRef.current[index] = el;
                        } else if (imageRef.current && imageRef.current[index]) {
                          delete imageRef.current[index];
                        }
                      }}


                        className="modalContainer">

                        <img
                          src={imageSrc[index]?.img || `data:image/jpeg;base64,${i.thumbnail_base64}`}
                          style={{
                            justifyContent: 'center',
                            alignContent: 'center',
                            width: '80px',
                            height: 'auto',
                            borderRadius: '4px'
                          }}

                          alt="Avatar"
                          className='image' />

                        <div>
                          <div className="modalCentered">
                            <div
                              style={{
                                padding: '2px 7px 8px 7px',
                                width: '100%',
                                display: 'flex'
                              }}
                            >
                              <div
                                style={{
                                  width: '85%',
                                  fontSize: '2px',
                                  fontWeight: '500',
                                  
                                }}
                              >
                                <div>
                                  <div>
                                    <b>Notes: </b>{' '}
                                    <span>
                                      {images[index]?.title ? `${images[index]?.title}` : 'No notes here'}
                                    </span>
                                  </div>
                                  <div>
                                    <b>Date:</b>{' '}
                                    <span>
                                      {moment(images[index]?.date).format('D MMM, YYYY h:mm A')}
                                    </span>
                                  </div>
                                  <div>
                                    <b>Location:</b>{' '}
                                    <span style={{ wordBreak: 'break-word' }}>
                                      {images[index]?.location}
                                    </span>
                                  </div>
                                  <div>
                                    <b>Lat/Long (within 1m): </b>
                                    <span>
                                      {images[index]?.lat?.slice(0, 7) +
                                        ' / ' +
                                        images[index]?.long?.slice(0, 7)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: '15%',
                                  display: 'flex',
                                  marginLeft: '10px'
                                }}
                              >
                                <img
                                  alt="ava"
                                  src={`data:image/jpeg;base64,${src}`}
                                  style={{
                                    width: '10px',
                                    height: '10px',
                                    margin: 'auto'
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="middle">
                        <div style={{ fontSize: '14px' }}>Preview Image</div>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Masonry>
            </Box>
          </TableRow>
        </>
      );
    } else {
      return (
        <>
          <TableRow>
            <Grid container className="gridcontainer">
              <p style={{ margin: 'auto', paddingTop: '10px' }}>No Images Attached Yet</p>
            </Grid>
          </TableRow>
        </>
      );
    }
  };

  return (
    <>
      <style>
        {`
              @keyframes spin {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(360deg);
                }
              }
            `}
      </style>
      <Page title="validation certificate" style={{ backgroundColor: 'white' }}>
        <Grid container className={classes.root} spacing={0} alignItems="center" justify="center">
          <div className="card">
            <Table>
              <TableBody>
                <TableRow>
                  <td className="header-cert">
                    <span>GeoFace</span> Validation Certificate
                  </td>
                </TableRow>
                <TableRow className={classes.borderBottom}>
                  <div className={classes.contentCell}>
                    {_.isEmpty(certData) ? (
                      renderLoadingSkeleton()
                    ) : (
                      <>
                        <Typography className="validation-number" style={{ color: 'black' }}>
                          V+V Validation Number:{' '}
                          <strong>
                            {' '}
                            <span style={{ color: '#5B80E1' }}>{certData.ver_id}</span>{' '}
                          </strong>
                        </Typography>
                        <div className="userData">
                          <Typography className={classes.value}>This certifies that</Typography>
                          <Typography className={classes.title}>
                            {certData.receiver_name}
                          </Typography>
                          <Typography className={`${classes.value} ${classes.located}`}>
                            was located at
                          </Typography>
                          <Typography className={classes.title}>
                            <span className={classes.underlinedTitle}>{certData.address}</span>
                          </Typography>
                          <Typography className={classes.value}>
                            on&nbsp;
                            <b>
                              <span>{
                                moment(certData.date).format("D MMM, YYYY h:mm A")}
                              </span>
                            </b>
                          </Typography>
                          <Typography className="latlong">
                            Lat/Long (within 1m):
                            <strong>
                              {' '}
                              {certData.latitude
                                ? certData.latitude?.slice(0, 8)
                                : 'N/A'} /{' '}
                              {certData.longitude ? certData.longitude?.slice(0, 8) : 'N/A'}{' '}
                            </strong>
                          </Typography>
                        </div>
                        <Box className="verification-container">
                          <Box className="verification-content">
                            {/* Status Section */}
                            <Box className="status-section">
                              {_.isEmpty(certData) ? (
                                <Skeleton
                                  variant="rectangular"
                                  sx={{ width: '100%', height: '30px' }}
                                />
                              ) : (
                                <Typography variant="h6" className="status-text">
                                  {!_.isEmpty(status) &&
                                    !_.isEmpty(trueStatus) &&
                                    renderSuccesOrFailed()}
                                </Typography>
                              )}
                            </Box>

                            {/* Info Section */}
                            <Box className="info-section">
                              <Box className="info-row">
                                <Typography className="label">Identity:</Typography>
                                <Typography className="value">
                                  {!_.isEmpty(status) && !_.isEmpty(trueStatus) && renderStatus()}
                                </Typography>
                              </Box>
                              <Box className="info-row">
                                <Typography className="label">Location:</Typography>
                                <Typography className="value">
                                  {!_.isEmpty(status) && !_.isEmpty(trueStatus) && renderStatus()}
                                </Typography>
                              </Box>
                            </Box>

                            {/* QR Section */}
                            <Box className="qr-section">
                              <Box
                                component="img"
                                src={`data:image/jpeg;base64,${qrSrc}`}
                                className="qr-image"
                                alt="QR Code"
                                loading="lazy"
                              />
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}
                  </div>
                </TableRow>
                <div>
                  <Table>
                    <TableBody>
                      <TableRow></TableRow>
                      <TableRow>
                        <Typography className="technology">
                          Validated using PATENTED V+V Technology
                        </Typography>
                      </TableRow>
                      {certImages.code == 200 ? (
                        renderImages()
                      ) : (
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '200px' }} />
                      )}
                    </TableBody>
                  </Table>
                </div>
                {Object.values(checked).includes(true) && (
                  <Box sx={{ margin: '0px 20px 0px 20px', padding: '0px' }}>
                    <FormGroup sx={{ fontSize: '12px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            onChange={(event) => selectAll(event.target.checked)}
                            checked={checkedAll}
                          />
                        }
                        label="Select All"
                      />
                      <Button
                        sx={{
                          float: 'left',
                          backgroundColor: '#5C81E4',
                          marginTop: '1px',
                          marginBottom: '10px'
                        }}
                        onClick={() => handleDownload()}
                        variant="contained"
                        startIcon={<CloudDownload />}
                      >
                        Download
                      </Button>
                    </FormGroup>
                  </Box>
                )}
                {!_.isEmpty(checked) &&
                  withTime &&
                  Object.keys(checked)
                    .filter((item) => checked[item] == true)
                    .map((i) => {
                      return (
                        <div id={`downloadablediv${i}`} style={{ display: 'none' }}>
                          <Download images={images[i]} checked={checked} cert={view} />
                        </div>
                      );
                    })}
                {modal && (
                  <Modal
                    open={modal}
                    onClose={closeButton}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <>
                      <div ref={imageRef} className="modalContainer">
                        <img
                          src={images[index].img}
                          alt="Avatar"
                          onLoad={(event) => {
                            setSize({
                              height: event.target.naturalHeight,
                              width: event.target.naturalWidth
                            });
                          }}
                          className={
                            !_.isEmpty(size) && size.height < size.width
                              ? 'modalImage'
                              : 'modalImages'
                          }
                          style={{ backgroundColor: 'black' }}
                        />
                          <div className="modalCentered">
                            <div
                              style={{
                                padding: '15px 22px 15px 22px',
                                display: 'flex'
                              }}
                            >
                              <div
                                style={{
                                  width: '85%',
                                  fontSize: '13px',
                                  fontWeight: '500'
                                }}
                              >
                                <div>
                                  <div>
                                    <b>Notes: </b>{' '}
                                    <span>
                                      {images[index].title ? images[index].title : 'No notes here'}
                                    </span>
                                  </div>
                                  <div>
                                    <b>Date:</b>{' '}
                                    <span>
                                      {moment(images[index].date).format('D MMM, YYYY h:mm A')}
                                    </span>
                                  </div>
                                  <div>
                                    <b>Location:</b>{' '}
                                    <span style={{ wordBreak: 'break-word' }}>
                                      {images[index].location}
                                    </span>
                                  </div>
                                  <div>
                                    <b>Lat/Long (within 1m): </b>
                                    <span>
                                      {images[index]?.lat?.slice(0, 7) +
                                        ' / ' +
                                        images[index]?.long?.slice(0, 7)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: '15%',
                                  display: 'flex',
                                  marginLeft: '10px'
                                }}
                              >
                                <img
                                  alt="ava"
                                  src={`data:image/jpeg;base64,${src}`}
                                  style={{
                                    width: '49px',
                                    height: '49px',
                                    margin: 'auto'
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                        <div className="fowardarrowCentered">
                          <div style={{ float: 'right' }}>
                            <IconButton
                              sx={{ color: 'white' }}
                              aria-label="upload picture"
                              component="span"
                              onClick={() => onMoveNextRequest(index)}
                            >
                              <ArrowForwardIcon />
                            </IconButton>
                          </div>
                        </div>
                        <div>
                          <div style={{ position: 'absolute', top: '50%' }}>
                            <IconButton
                              sx={{ color: 'white' }}
                              aria-label="upload picture"
                              component="span"
                              onClick={() => onMovePrevRequest(index)}
                            >
                              <ArrowBackIcon />
                            </IconButton>
                          </div>
                        </div>
                        <div>
                          <div style={{ position: 'absolute', top: '0%' }}>
                            <IconButton
                              sx={{ color: 'white' }}
                              aria-label="upload picture"
                              component="span"
                              onClick={() => closeButton()}
                            >
                              <CloseOutlined />
                            </IconButton>
                          </div>
                        </div>
                        <div>
                          {loading && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                              }}
                            >
                              <LoopIcon
                                style={{
                                  color: 'white',
                                  fontSize: '50px',
                                  animation: 'spin 1s linear infinite'
                                }}
                              />
                            </div>
                          )}
                        </div>

                      </div>

                    </>
                  </Modal>
                )}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Page>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    view: state.viewCert,
    certImages: state.certImages,
    certData: state.viewCert
  };
};

export default connect(mapStateToProps, {})(GeneralCert);
