import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 50,
  width:"100%",
  display: 'flex',
  '@media (min-width: 600px)': {
    paddingLeft: "10px",
    paddingRight:"5px"
  },
}));


const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width:"100%",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: "100%", boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  }
}));

// ----------------------------------------------------------------------

SearchValidationToolBar.propTypes = {
  numSelected: PropTypes.number,
  filter: PropTypes.string,
  onFilter: PropTypes.func,
  filterPlaceHolder: PropTypes.string
};

export default function SearchValidationToolBar({ numSelected, filter, onFilter, filterPlaceHolder }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          sx={{paddingLeft:"15px",
          width:"100%",
          '@media (min-width: 1300px)': {
            fontSize:"16px",
          }
          }}
          size="small"
          value={filter}
          onChange={onFilter}
          placeholder={filterPlaceHolder}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}
    </RootStyle>
  );
}
