import React from 'react'
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, FieldArray, getIn } from 'formik';
import { connect } from "react-redux";
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import debounce from 'lodash.debounce';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/MobileDateTimePicker';
import moment from 'moment';
// material
import {
    Card,
    Stack,
    TextField,
    Container,
    Typography,
    Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createVoucher } from '../../actions/vouchersAction';
import _, { replace } from 'lodash';
import Page from '../../components/Page';
import { styled } from '@mui/material/styles';


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
}));

function CreateVoucher(props) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [date, setDate] = useState(moment(new Date()).add(1,'day').format("D MMM, YYYY h:mm a"));


    const AddSchema = Yup.object().shape({
        amount:Yup.number().positive().min(1,"Voucher Amount should be greater than 0").required('Voucher Amount is required'),
    });

    const formik = useFormik({
        initialValues: {
            amount:'',
        },
    validationSchema: AddSchema,
        onSubmit: (values, {resetForm}) => {
            resetForm();
            setIsLoading(true);
            const fDate = moment(new Date(date)).format("YYYY-MM-DD");
            const fd = new FormData();
            fd.append('valid_date',fDate);
            fd.append('amount',values.amount);
            props.createVoucher(fd);
            props.onDismis();
        }
    });

    // useEffect(() => {
    //     setIsLoading(false);
    //     if(!_.isEmpty(props.alerts.errors)) {
    //         setTelephoneError(props.alerts.errors.telephone);
    //         setEngineError(props.alerts.errors.engine_no);
    //         setChasisError(props.alerts.errors.chasis_no);
    //     }
    //     if(props.alerts.status == 'success') {
    //         navigate('/dashboard/app', { replace: true });
    //     }
    // }, [props.alerts]);

    // useEffect(() => {
    //     if(values.agreed_price && values.deposite_price) {
    //         var balance = values.agreed_price-values.deposite_price;
    //         values.balance = balance;
    //     } else {
    //         values.balance = 0;
    //     }
    // });

    const handleDate = (event) => {
        setDate(event);
    };

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, handleChange, handleBlur } = formik;

    return (
        <Page title="Create Voucher">
            <Container maxWidth="lg">
                <Card sx={{padding:"1em"}}>
                    <Stack sx={{ mb: 5 }}>
                        <Typography variant="h4" gutterBottom>
                            Create Voucher
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Enter details below.</Typography>
                    </Stack>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: 'text.primary' }}>Select Expiry Date</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} sx={{padding:"0px",width:"100%"}}>
                                        <DatePicker
                                            size="small"
                                            formatDate={(date) => moment(new Date()).format("D MMM, YYYY h:mm a")}
                                            value={date}
                                            minDateTime={new Date()}
                                            onChange={handleDate}
                                            renderInput={(params) => <TextField 
                                                fullWidth
                                                size="small" 
                                                {...getFieldProps('expiry_date')}
                                                error={Boolean(touched.expiry_date && errors.expiry_date)}
                                                helperText={touched.expiry_date && errors.expiry_date}
                                                {...params}
                                            />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: 'text.primary' }}>Enter Amount</Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('amount')}
                                        InputProps={{ inputProps: { min: 1, step: 'any' } }}
                                        error={Boolean(touched.amount && errors.amount)}
                                        helperText={touched.amount && errors.amount}
                                        variant="outlined"
                                    />   
                                </Grid>
                            </Grid>
                            <LoadingButton
                                size="medium"
                                type="submit"
                                variant="contained"
                                loading={isLoading}
                                sx={{backgroundColor: "rgb(92, 129, 228)",float:"right",margin:"20px 0px 10px 10px"}}
                            >
                                Create
                            </LoadingButton>
                        </Form>
                    </FormikProvider>
                </Card>
            </Container>
        </Page>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        alerts: state.alerts,
    }
}

export default connect(mapStateToProps, { createVoucher })(CreateVoucher);

