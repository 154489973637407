import React from 'react';
import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import listFill from '@iconify/icons-eva/list-outline';
import voucher from '@iconify/icons-eva/file-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import settingsFill from '@iconify/icons-eva/settings-fill';
import bellFill from '@iconify/icons-eva/activity-fill';
import peoplefill from '@iconify/icons-eva/people-fill';
import searchfill from '@iconify/icons-eva/search-fill';
import logfill from '@iconify/icons-eva/eye-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Validations List',
    path: '/dashboard/app',
    icon: getIcon(listFill)
  },
  {
    title: 'Transactions List',
    path: '/dashboard/transactions',
    icon: getIcon(bellFill)
  },
  {
    title: 'Users List',
    path: '/dashboard/users',
    icon: getIcon(peoplefill)
  },
  {
    title:'Vouchers',
    path:'/dashboard/vouchers',
    icon:getIcon(voucher)
  },
  {
    title:'App Settings',
    path:'/dashboard/settings',
    icon:getIcon(settingsFill)
  },
  {
    title:'Search Validation',
    path:'/dashboard/search_validation',
    icon:getIcon(searchfill)
  }
];

export default sidebarConfig;
