
import React from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
}));

// ----------------------------------------------------------------------

export default function PrivacyPolicy() {
    return (
        <RootStyle title="Privacy Policy">
            <Container fixed>
                <Box>
                    <Typography variant="h3" paragraph>
                        Privacy
                    </Typography>
                    <Box sx={{fontSize:"18px", color: 'text.secondary'}}>
                        Thank you for visiting GeoFace.com.au, website is managed by the GeoFace team. Your privacy policy isn't complaint with our User Data policy. Under the User Data policy, you must link to a privacy on your app's tore listing page and within your app, regardless of your app's accessto sensitive permissions or data(apps do not access any personal and sensitive user data must still submit a privacy policy).
                        Please make sure your privacy olicy is available on an active URL, applies to your app,and specifically covers user privacy.<br />
                        Please review the following compliance checkist fo privacy policies. Your privacy policy must: <br />
                            <ul style={{marginLeft:"20px"}}>
                                <li>
                                    Be linked on your app's store listing page in Play Console and within the ap itself.
                                </li>
                                <li>
                                    Clearly indiate that the page is a privacy policy(for example, listed as "privacy policy" in the title or has "privacy" in the URL and body of page).
                                </li>
                                <li>
                                    Be readable in standard broswer without any plug-ins or special handlers (for examples, no PDFs or uncommon MIME types).
                                </li>
                                <li>
                                    Be non-editable
                                </li>
                                <li>
                                    Have a URL that links to a single governing privacy policy in your website and/or store listing rather than one that points to multiple privacy policies. 
                                </li>
                                <li>
                                    Include the name of the app or entity (for example, developers, or company) named in the app's store listing. 
                                </li>
                            </ul>
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h3" paragraph>
                        How we collect the information
                    </Typography>
                    <Box sx={{fontSize:"18px", color: 'text.secondary'}}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h3" paragraph>
                        Contacting us
                    </Typography>
                    <Box sx={{fontSize:"18px", color: 'text.secondary'}}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </Box>
                </Box>
            </Container>
        </RootStyle>
    );
}
