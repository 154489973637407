import React from 'react'
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Typography,
  TableContainer,
  Container,
  TableBody,
  TableRow,
  TableCell,
  Button
} from '@mui/material';
import { connect } from 'react-redux';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import _ from 'lodash';
import { checkNotificationTime } from '../actions/notificationStatus';
import { fetchConfigInfo } from '../actions/settingsAction';
import ModalCompoennt from './Portal/ModalCompoennt';
import UpdateNotificationDetails  from './UpdateNotificationDetails';
import Edit from "@material-ui/icons/Edit";
import moment from 'moment';
import UpdateSettings from './UpdateSettings';

// ----------------------------------------------------------------------

function NotificationStatus(props) {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  useEffect(() => {
    var bodyFormData = new FormData();
    bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
    props.checkNotificationTime(bodyFormData);
    props.fetchConfigInfo(bodyFormData);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleUpdateOpen = () => {
    setOpenUpdate(true);
  }

  const closeButton = () => {
    setOpen(false);
    setOpenUpdate(false);
  }

  const renderList = (notified_time) => {
    if(!_.isEmpty(notified_time)) {
      return (
        <TableRow
          hover
          key={notified_time.code}
        >
          <TableCell align="left" style={{padding:"10px"}}>{moment(notified_time.last_notified).format("D MMM, YYYY h:mm a")}</TableCell>
          <TableCell align="left" style={{padding:"10px"}}>{moment(notified_time.next_notified).format("D MMM, YYYY h:mm a")}</TableCell>
          <TableCell align="left" style={{padding:"10px"}}>
            <Button variant="outlined" startIcon={<Edit />} onClick={handleOpen} sx={{background:"#5C81E4", color:"white", border:"none", '&:hover': {
                background:"#5C81E4", 
                color:"white",
                border:"none"
              },}}>
              Update
            </Button>
          </TableCell>
        </TableRow>
      );
    }
  } 

  const renderConfigList = (settings) => {
    if(!_.isEmpty(settings)) {
      return (
        <TableRow
            hover
            key={settings.code}
        >
            <TableCell align="left" style={{padding:"15px"}}>{settings.verification_radius}</TableCell>
            <TableCell align="left" style={{padding:"15px"}}>{settings.verification_timeout}</TableCell>
            <TableCell align="left" style={{padding:"10px"}}>
              <Button variant="outlined" startIcon={<Edit />} onClick={handleUpdateOpen} sx={{background:"#5C81E4", color:"white", border:"none", '&:hover': {
                  background:"#5C81E4", 
                  color:"white",
                  border:"none"
                },}}>
                Update
              </Button>
          </TableCell>
        </TableRow>
      );
    }
  }

  return (
    <Page title="Notification Status">
      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Notification Status
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableRow>
                  <TableCell sx={{textAlign:"left"}}>
                    <b style={{fontSize:"0.875rem", fontWeight:"600"}}>Last Notified</b>
                    <span style={{display:"block",fontSize:"0.8rem"}}>This is the date when the last notification was sent</span>
                  </TableCell>
                  <TableCell sx={{textAlign:"left"}}>
                    <b style={{fontSize:"0.875rem", fontWeight:"600"}}>Next Notification Time</b>
                    <span style={{display:"block", fontSize:"0.8rem"}}>This is the date when the next notification will be sent</span>
                  </TableCell>
                  <TableCell>
                    <b style={{fontSize:"0.875rem", fontWeight:"600"}}>Update Next Notification Time</b>
                  </TableCell>
                </TableRow>
                <TableBody>
                  {renderList(props.notificationTime)}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      <Container maxWidth="100%" sx={{marginTop:"20px"}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Verification Settings
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableRow>
                  <TableCell sx={{textAlign:"left"}}>
                    <b style={{fontSize:"0.875rem", fontWeight:"600"}}>Location Radius</b>
                    <span style={{display:"block",fontSize:"0.8rem"}}>This is the radius where you are allowed to verify the location.</span>
                  </TableCell>
                  <TableCell sx={{textAlign:"left"}}>
                    <b style={{fontSize:"0.875rem", fontWeight:"600"}}>Responce Time</b>
                    <span style={{display:"block", fontSize:"0.8rem"}}>This is the notification responce time.</span>
                  </TableCell>
                  <TableCell>
                    <b style={{fontSize:"0.875rem", fontWeight:"600"}}>Update Settings</b>
                  </TableCell>
                </TableRow>
                <TableBody>
                  {renderConfigList(props.settings)}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      {
        open && 
          <ModalCompoennt open={open} onClose={closeButton} title="Update Next Verification Date" width="sm">
            <UpdateNotificationDetails next_date={props.notificationTime} verification_timeout={props.settings.verification_timeout} onDismis={closeButton} />
          </ModalCompoennt>
      }
      {
        openUpdate && 
          <ModalCompoennt open={openUpdate} onClose={closeButton} title="Update Settings" width="sm">
            <UpdateSettings setting={props.settings} onDismis={closeButton} />
          </ModalCompoennt>
      }
    </Page>
  );
}

const mapStateToProps = state => {
    return {
      notificationTime: state.notificationStatus,
      settings:state.settings
    }
}

export default connect(mapStateToProps, { checkNotificationTime, fetchConfigInfo })(NotificationStatus);