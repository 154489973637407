import React from 'react'
import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { connect } from "react-redux";
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useLocation } from 'react-router-dom';
import { isAndroid, isIOS } from "react-device-detect";
// material
import {
    Link,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { updateUserPassword } from '../../actions/usersAction';

// ----------------------------------------------------------------------

function UpdateForm(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isFirstRun = useRef(true);
    const AddSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        repeat_password: Yup.string().required('Repeat Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            repeat_password:''
        },
    validationSchema: AddSchema,
        onSubmit: (values, {resetForm}) => {
            resetForm();
            setIsLoading(true);
            values.token = id;
            props.updateUserPassword(values);
        }
    });

    useEffect(() => {
        setIsLoading(false);
    }, [props.alerts]);

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const handleShowRepeatPassword = () => {
        setShowRepeatPassword((show) => !show);
    };

        // useEffect(() => {
        //     if (isFirstRun.current) {
        //         isFirstRun.current = false;
        //         return;
        //     }
        //     if(props.alerts.status == 'success') {
        //         if (isAndroid) {
        //             const url =
        //             "intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end";
            
        //             window.location.replace(url);
        //         } else if (isIOS) {
        //             window.location.replace("instagram://");
        //             setTimeout(() => {
        //             window.location.replace(
        //                 "https://apps.apple.com/us/app/instagram/id389801252"
        //             );
        //             }, 10000);
        //         }
        //     }

        // }, [props.alerts.status]);

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="New Password*"
                        {...getFieldProps('password')}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                            </InputAdornment>
                        )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showRepeatPassword ? 'text' : 'password'}
                        label="Repeat Password*"
                        {...getFieldProps('repeat_password')}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleShowRepeatPassword} edge="end">
                                <Icon icon={showRepeatPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                        error={Boolean(touched.repeat_password && errors.repeat_password)}
                        helperText={touched.repeat_password && errors.repeat_password}
                        />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    sx={{backgroundColor: '#5C81E4',}}
                >
                    Update
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        alerts: state.alerts,
    }
}

export default connect(mapStateToProps, { updateUserPassword })(UpdateForm);

