import React from 'react';
import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import {
  Card,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Box,
  Button
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import { fetchAllTransactions } from '../../actions/transactionAction';
import _ from 'lodash';
import Page from '../../components/Page';
import { styled } from '@mui/material/styles';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import ResetIcon from '@mui/icons-material/Restore';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
})); 

const TABLE_HEAD = [
  { id: 'transaction_id', label: 'Transaction ID', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'in/out', label: 'Paid/Deduct', alignRight: false },
  { id: 'payment_method', label: 'Payment Method', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'actions', label: 'Actions' },
];

function TransactionsList(props) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [filterTransactionID, setFilterTransactionID] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterPaymentMethod, setFilterPaymentMethod] = useState('');
  const [filterLocation, setFilterLocation] = useState('');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    props.fetchAllTransactions();
  }, []);

  useEffect(() => {
    if(!_.isEmpty(props.transactions.paginate)) {
      setTotalPages(props.transactions.paginate.TotalPages);
    }
  },[props.transactions.paginate]);

  const renderDirection = (t) => {
    if(t.direction == 0) {
      return <>
        <Box>
          <ArrowUpwardIcon sx={{color:"red"}}/>
        </Box>
      </>
    } else {
      return <>
        <Box>
          <ArrowDownwardIcon sx={{color:"green"}}/>
        </Box>
      </>
    }
  }

  const renderCardBody = () => {
    if(!_.isEmpty(props.transactions.data)) {
      let data = Object.values(props.transactions.data);
      return data.map((t) => {
        return <TableRow key={t.id}>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{t.id}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{t.name}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{t.email}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{t.amount}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{renderDirection(t)}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{t.direction == 1 ? t.descrip : ''}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}} colSpan={2}>{t.direction == 0 ? t.descrip : ''}</TableCell>
        </TableRow>
      })
    } else {
      return <>
        <TableRow>
          <TableCell colSpan={7}>
            No Transaction Exist
          </TableCell>
        </TableRow>
      </>
    }
  }

  const handleFilterByTransactionId = (event) => {
    setFilterTransactionID(event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterByEmail = (event) => {
    setFilterEmail(event.target.value);
  };

  const handleFilterByLocation = (event) => {
    setFilterLocation(event.target.value);
  };

  const handleFilterByPaymentMethod = (event) => {
    setFilterPaymentMethod(event.target.value);
  };

  const handleFilterByStartDate = (event) => {
    setFilterStartDate(event);
  };

  const handleFilterByEndDate = (event) => {
    setFilterEndDate(event);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    props.fetchAllTransactions(newPage)
  }

  const searchRecord = () => {
    if(filterTransactionID != '' || filterName != '' || filterEmail != '' || filterLocation != '' || filterPaymentMethod != '' || filterStartDate != null || filterEndDate != null) {
      const values = {
        transaction_id:filterTransactionID,
        name: filterName,
        email: filterEmail,
        location: filterLocation,
        payment_method: filterPaymentMethod,
        start_date: filterStartDate != null ? moment(new Date(filterStartDate)).format("YYYY-MM-DD") : '',
        end_date: filterEndDate!= null ? moment(new Date(filterEndDate)).format("YYYY-MM-DD") : ''
      };
      console.log(values);
    } else {
      console.log('insert record');
    }
  }

  const handleReset = () => {
    setFilterTransactionID('');
    setFilterName('');
    setFilterEmail('');
    setFilterLocation('');
    setFilterPaymentMethod('');
    setFilterStartDate(null);
    setFilterEndDate(null);
  }

  return (
    <Page title="Transaction List">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Grid>
            <Typography variant="h4" gutterBottom>
              Transaction List 
            </Typography>
          </Grid>
        </Stack>
        <Card>
          <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={props.transactions.length}
                  numSelected={selected.length}
                />
                <TableRow>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterTransactionID}
                      onFilter={handleFilterByTransactionId}
                      filterPlaceHolder= "Id..."
                      showUpdateButton={false}
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={2}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterName}
                      onFilter={handleFilterByName}
                      filterPlaceHolder= "Name..."
                      showUpdateButton={false}
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterEmail}
                      onFilter={handleFilterByEmail}
                      filterPlaceHolder= "Email/Phone..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={2}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterPaymentMethod}
                      onFilter={handleFilterByPaymentMethod}
                      filterPlaceHolder= "PaymentMethod..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterLocation}
                      onFilter={handleFilterByLocation}
                      filterPlaceHolder= "Location..."
                    />
                  </TableCell>
                  {/* <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={1}>
                    <UserDatePicker
                      numSelected={selected.length}
                      filter={filterStartDate}
                      onFilter={handleFilterByStartDate}
                      filterPlaceHolder= "From..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={1}>
                    <UserDatePicker
                      numSelected={selected.length}
                      filter={filterEndDate}
                      onFilter={handleFilterByEndDate}
                      filterPlaceHolder= "To..."
                    />
                  </TableCell> */}
                  <TableCell colSpan={2} sx={{display:"flex"}}>
                    <Button variant="contained" onClick={searchRecord} sx={{backgroundColor:"rgb(92, 129, 228)",marginRight:"15px"}} startIcon={<SearchIcon />}>Search</Button>
                    <Button variant="contained" onClick={handleReset} sx={{backgroundColor:"red"}} startIcon={<ResetIcon />} >Reset</Button>
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterLocation}
                      onFilter={handleFilterByLocation}
                      filterPlaceHolder= "Location..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={1}>
                    <UserDatePicker
                      numSelected={selected.length}
                      filter={filterStartDate}
                      onFilter={handleFilterByStartDate}
                      filterPlaceHolder= "From..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={1}>
                    <UserDatePicker
                      numSelected={selected.length}
                      filter={filterEndDate}
                      onFilter={handleFilterByEndDate}
                      filterPlaceHolder= "To..."
                    />
                  </TableCell>
                  <TableCell colSpan={2} sx={{display:"flex"}}>
                    <Button variant="contained" onClick={searchRecord} sx={{backgroundColor:"rgb(92, 129, 228)",marginRight:"15px"}} startIcon={<SearchIcon />}>Search</Button>
                    <Button variant="contained" onClick={handleReset} sx={{backgroundColor:"red"}} startIcon={<ResetIcon />} >Reset</Button>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow> */}
                <TableBody>
                  {renderCardBody()}
                </TableBody>
              </Table>
              <Pagination sx={{float:"right",marginTop:"10px",marginBottom:"10px"}} page={page} count={totalPages} variant="outlined" color="primary" onChange={handlePageChange} />
            </TableContainer>
          </Scrollbar>
        </Card>
      </Page>
  )
}
const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      alerts: state.alerts,
      transactions: state.transactions,
  }
}

export default connect(mapStateToProps, { fetchAllTransactions })(TransactionsList);

