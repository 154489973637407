import React from 'react';
import * as Yup from 'yup';
import { useState, useEffect, useMemo, useRef } from 'react';
import { sentenceCase } from 'change-case';
import { useFormik, Form, FormikProvider, FieldArray, getIn } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import moment from 'moment';
import { ceil, filter } from 'lodash';
import {
Link,
Card,
Stack,
Typography,
TableContainer,
TableHead,
Table,
TableBody,
TableRow,
TableCell,
Grid,
Box,
Button,
Container,
TablePagination,
TextField
} from '@mui/material';
import { fetchTerms, updateIrhereTerms, updateBvdTerms, updateAvdTerms } from '../../actions/termsAction';
import _ from 'lodash';
import Page from '../../components/Page';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { UserListHead, UserListToolbar, UserMoreMenu, UserDatePicker } from '../../components/_dashboard/user';
import FilterVouchers from '../../components/_dashboard/Vouchers/FilterVouchers';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Label from '../../components/Label';
import ModalCompoennt from '../Portal/ModalCompoennt';
import SearchNotFound from '../../components/SearchNotFound';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';  
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
})); 

function TermsList(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [irhereTerms, setIrhereTerms] = useState('');
    const [avdTerms, setAvdTerms] = useState('');
    const [bvdTerms, setBvdTerms] = useState('');
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        setIrhereTerms(props.terms.i_terms);
        setAvdTerms(props.terms.avdt);
        setBvdTerms(props.terms.bvdt);
    },[props.terms])

    const formik = useFormik({
        initialValues: {
            irhereTerms:'',
            bvdt:'',
            avdt:''
        },
        onSubmit: (values, {resetForm}) => {
            // setIsLoading(true);
            if(irhereTerms != '' && irhereTerms.toLowerCase() != props.terms.i_terms.toLowerCase()) {
                const fd = new FormData();
                fd.append('terms',irhereTerms);
                props.updateIrhereTerms(irhereTerms,fd);
            }
            if(bvdTerms != '' && bvdTerms.toLowerCase() != props.terms.bvdt.toLowerCase()) {
                const fd = new FormData();
                fd.append('terms',bvdTerms);
                props.updateBvdTerms(bvdTerms,fd);
            }
            if(avdTerms != '' && avdTerms.toLowerCase() != props.terms.avdt.toLowerCase()) {
                const fd = new FormData();
                fd.append('terms',avdTerms);
                props.updateAvdTerms(avdTerms,fd);
            }
            // resetForm();
            // props.onDismis();
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, handleChange, handleBlur } = formik;

    const closeButton = () => {
        // setUpdateOpen(false);
    }
    
    useEffect(() => {
        props.fetchTerms();
    }, []);

    return (
        <Page title="App Terms">
            <Container maxWidth="lg">
               <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant="h4" gutterBottom>
                            App Terms
                        </Typography>
                    </Grid>
                </Grid>
               </Stack>
                <Card sx={{padding:"1em"}}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Grid container sspacing={2}>
                        <Grid item xs={10}>
                            <Typography variant="h4" gutterBottom>
                                Update
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                <Typography sx={{ color: 'text.primary',fontWeight:"600" }} gutterBottom>iRhere Terms</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        value={irhereTerms}
                                        minRows={5}
                                        maxRows={10}
                                        type="text"
                                        onChange={(e) => setIrhereTerms(e.target.value)}
                                        // {...getFieldProps('irhere_terms')}
                                        // error={Boolean(touched.irhere_terms)}
                                        // helperText={touched.irhere_terms}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: 'text.primary',fontWeight:"600" }} gutterBottom >Before Verification Document Terms</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        minRows={5}
                                        maxRows={10}
                                        type="text"
                                        value={bvdTerms}
                                        onChange={(e) => setBvdTerms(e.target.value)}
                                        // {...getFieldProps('before_verification_document_terms')}
                                        // error={Boolean(touched.before_verification_document_terms && errors.before_verification_document_terms)}
                                        // helperText={touched.before_verification_document_terms && errors.before_verification_document_terms}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: 'text.primary',fontWeight:"600" }} gutterBottom>After Verification Document Terms</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        minRows={5}
                                        maxRows={10}
                                        type="text"
                                        value={avdTerms}
                                        onChange={(e) => setAvdTerms(e.target.value)}
                                        // {...getFieldProps('after_verification_document_terms')}
                                        // error={Boolean(touched.after_verification_document_terms && errors.after_verification_document_terms)}
                                        // helperText={touched.after_verification_document_terms && errors.after_verification_document_terms}
                                    />
                                </Grid>
                            </Grid>
                            <LoadingButton
                                size="medium"
                                type="submit"
                                variant="contained"
                                loading={isLoading}
                                sx={{backgroundColor: "rgb(92, 129, 228)",float:"right",margin:"20px 0px 10px 10px"}}
                            >
                                Update
                            </LoadingButton>
                        </Form>
                    </FormikProvider>
                </Card>
            </Container>
        </Page>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        alerts: state.alerts,
        terms: state.terms
    }
}
export default connect(mapStateToProps, { fetchTerms, updateIrhereTerms, updateBvdTerms, updateAvdTerms })(TermsList);