
import * as React from 'react';
import {
    Typography,
    IconButton
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import _ from 'lodash';


const renderModalTitle = (props) => {
    if(!_.isEmpty(props.user)) {
        return <b style={{paddingLeft:"8.5px"}}>{props.user.name+"'s "+props.title}</b>; 
    } else {
        return <b>{props.title}</b>;
    }
    
}

export default function ModalCompoennt(props) {
    const { color = "#5C81E4", scroll  } = props;
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            scroll={props.scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth={props.width}
        >
            <Typography sx={{background:color,color:"white",padding:"18px",borderBottom:"1px solid black"}}>
                {renderModalTitle(props)} 
                <IconButton onClick={props.onClose} sx={{float:"right",padding:"0px", color:"white"}}>
                    <CloseOutlined />
                </IconButton>
            </Typography>
            <DialogContent dividers={scroll === 'paper'} sx={{width:"100%",padding:"0px"}}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                {props.children}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
