
import React from 'react';
// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import reducers from './reducers';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers,composeEnhancer(applyMiddleware(reduxThunk)));

// ----------------------------------------------------------------------

ReactDOM.render(
  <Provider store={store}>
    {/* <HelmetProvider> */}
      <BrowserRouter basename='/'>
        <App />
      </BrowserRouter>
    {/* </HelmetProvider> */}
  </Provider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
