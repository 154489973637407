import React from 'react';

import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import User from './pages/User';
import NotFound from './pages/Page404';
import UsersList from './pages/Users/UsersList';
import UserDetails from './pages/UserDetails';
import AddAdmin from './pages/admins/AddAdmin';
import ViewCert from './pages/ViewCert';
import NotificationStatus from './pages/NotificationStatus';
import { useSearchParams } from 'react-router-dom';
import UserPasswordReset from './pages/UserPasswordReset';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TransactionsList from './pages/Transactions/TransactionsList';
import VouchersList from './pages/Vouchers/VouchersList';
import Terms from './pages/AppSettings/Terms';
import SearchValidation from './pages/SearchValidation';

// ----------------------------------------------------------------------

export default function Router() {
  const isLoggedIn  = sessionStorage.getItem("isSignedIn");
  let element = useRoutes([
    {
      path: '/dashboard',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'users', element: <UsersList /> },
        { path: 'add/admin', element: <AddAdmin /> },
        { path: 'viewcert', element: <ViewCert /> },
        { path: 'notification/status', element: <NotificationStatus /> },
        { path: 'transactions', element: <TransactionsList /> },
        { path: 'vouchers', element: <VouchersList /> },
        { path: 'settings', element: <Terms /> },
        { path: 'search_validation', element: <SearchValidation /> }
      ]
    },
    { path: '/',  element: <Navigate to="login" replace /> },
    // { path: '/admin',  element: <Navigate to="/login" replace /> },
    { path: 'privacy-policy',  element: <PrivacyPolicy/> },
    { path: 'viewcert',  element: <ViewCert /> },
    { path: 'reset_password',  element: <UserPasswordReset /> },
    { path: 'viewgeneralcert', element: <ViewCert /> },
    { path: 'login',  element: isLoggedIn ? <Navigate to="/dashboard/vouchers" /> : <Login /> },
    { path: '404',  element: <NotFound />},
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
  return element;
}
